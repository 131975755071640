import React from 'react';
import PropTypes from 'prop-types';

import { MainStage } from '@ccg/middleeast';

import PageTemplate from '../templates/page';
import Embeds from '../../shared/Embeds/Embeds';
import { getNextLinkProps } from '../../../helper';

const Home = ({ data }) => {
  const { navigation, footer, seoDefaults, cookieLayer, entry, social } = data;

  const link = getNextLinkProps(entry.stageLinkTo);

  return (
    <PageTemplate
      navigation={navigation}
      seoDefaults={seoDefaults}
      seo={entry}
      footer={footer}
      social={social}
      cookieLayer={cookieLayer}
    >
      <MainStage
        headline={entry.headline}
        subline={entry.subline}
        link={link}
        hideBreadcrumb
        image={
          entry.image && entry.image.length > 0
            ? {
                ...entry.image[0],
                srcset: {
                  '1440w': entry.image[0].width1440,
                  '960w': entry.image[0].width960,
                  '720w': entry.image[0].width720,
                  '375w': entry.image[0].width375
                },
                source: {
                  '1x': entry.image[0].width400,
                  '2x': entry.image[0].width800,
                  '3x': entry.image[0].width1200
                }
              }
            : undefined
        }
        video={entry.video && entry.video.length > 0 ? entry.video[0] : undefined}
        loop={entry.loop}
      />

      <Embeds embedsCopy={entry.embedsCopy} embeds={entry.embeds} />
    </PageTemplate>
  );
};

Home.propTypes = { data: PropTypes.shape().isRequired };

Home.defaultProps = {};

export default Home;
